<template>
  <v-app>
    <div class="settingsPage">
      <div class="container">
        <h1>Unsubscribe</h1>
        <br />
        <br />
        <v-card
          class="mx-auto"
          max-width="500"
        >
        
          <v-card-text v-if="showMessage">
            <v-list
              subheader
              three-line
            >
              
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>You email address has been unsubscribed from the heedi mailing list.</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              
              
            </v-list>
          </v-card-text>

          

          
        </v-card>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

      </div>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>
  //import somethingModule from '@/store/something'
  import '@/plugins/vuetify'
  import VueFooter from '@/components/VueFooter'
  import feedbackModule from '@/store/feedback'
  import { mapActions } from 'vuex'

  export default {
    name: "group",
    components: {
      VueFooter,
      //ThoughtIntake
    },
    data () {
      return {
        
        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],
        emailRules: [ v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid' ],
        email: null,
        isFormValid: false,
        showThankYou: false,
        showMessage: false,


      }
    },
    methods: {
      ...mapActions('feedback', [
        'saveEmail',
        'unsubscribeEmail',
      ]),
      
    },
    beforeCreate () {
      //if(!this.$store.state.something) this.$store.registerModule('something', somethingModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      // if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
    },
    created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/unsubscribe'});
    },
    async mounted () {

      if(this.$route.params.emailId) {
        var bool = await this.unsubscribeEmail(this.$route.params.emailId)
        if(bool) {
          this.showMessage = true
        }
        
      }

      

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

  .settingsPage {
    background-color: transparent;
  }
  .inputContainer {
    width: 70%;
    margin: 0 auto;
  }
  .thankYouMessage {
    text-align: center;
    margin: 0 auto;
  }
</style>
